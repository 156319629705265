<template>
  <div class="about">
    <div class="titles azjgs">
      <input-list @setData="getData" iptType="1"></input-list>
      <div>
        联系电话：
        <el-input type="number" v-model="cTelephoneNum" placeholder="请输入联系电话" clearable></el-input>
      </div>
      <div>
        联系人姓名：
        <el-input v-model="cTeleName" placeholder="请输入联系人" clearable></el-input>
      </div>
      <span>
        <el-button v-if="$has('viewMgrOrg')" type="success" size="mini" icon="el-icon-search" @click="searchs(1, pagesize)">查 询</el-button>
        <el-button v-if="$has('viewMgrOrg')" type="info" size="mini" icon="el-icon-refresh-right" @click="reserts">重 置</el-button>
        <el-button v-if="$has('addMgrOrg')" type="warning" size="mini" icon="el-icon-plus" @click="openDialog(0, 0)">新 增</el-button>
      </span>
    </div>
    <div class="cont">
      <el-table :data="tableData" height="calc(100% - 40px)">
        <el-table-column prop="areaName" label="区域名称" width="101"></el-table-column>
        <el-table-column prop="name" label="机构名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address" label="地址" show-overflow-tooltip></el-table-column>
        <el-table-column prop="phone" label="联系电话"></el-table-column>
        <el-table-column prop="contactName" label="联系人"></el-table-column>
        <el-table-column prop="longitude" label="经度"></el-table-column>
        <el-table-column prop="latitude" label="纬度"></el-table-column>
        <el-table-column prop="useFlag" label="状态">
          <template slot-scope="{ row }">
            <div>{{ row.useFlag == 0 ? '启用' : '禁用' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="updateDt" label="更新时间" width="160"></el-table-column>
        <el-table-column label="操作" width="310">
          <template slot-scope="{ row }">
            <el-button v-if="$has('editMgrOrg')" @click="openDialog(1, row)" type="primary" size="small" icon="el-icon-edit-outline">编 辑</el-button>
            <el-button v-if="$has('delMgrOrg')" type="danger" size="small" @click="deletes(row.id)" icon="el-icon-close">删 除</el-button>
            <el-button type="success" size="small" @click="openDialog(2, row)" icon="el-icon-view">机构授权码</el-button>
          </template>
        </el-table-column>
        <div slot="empty" class="empty">
          <img src="../../public/home/noData.svg" width="320" alt="" />
          <span>暂无数据</span>
        </div>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currpage" :page-sizes="[10, 20, 100, 500, 1000, 5000, 10000]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totals"> </el-pagination>
      <ToTop />
      <!-- 新增,编辑,授权码模态框 -->
      <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%" :modal="true" :close-on-click-modal="false" v-dialogDrag center @close="cancles">
        <div class="dialogsC">
          <div class="newDialog" v-if="!isLook">
            <div v-if="isgxqy">
              <i style="color: red">* </i>
              <span>区域名称：</span>
              <el-select v-model="paramsObj.areaId" placeholder="全部" clearable :disabled="isUseFlag" filterable>
                <el-option :label="item.name" v-for="(item, index) in cSuperiorArr" :key="index" :value="item.id"></el-option>
              </el-select>
            </div>
            <div>
              <i style="color: red">* </i>
              <span>机构名称：</span>
              <el-input placeholder="请输入机构名称" v-model="paramsObj.name" clearable></el-input>
            </div>
            <div>
              <i style="color: red">* </i>
              <span>地址：</span>
              <el-input placeholder="请输入地址" v-model="paramsObj.address" clearable></el-input>
            </div>
            <div>
              <i style="color: red">* </i>
              <span>联系人：</span>
              <el-input placeholder="请输入联系人" v-model="paramsObj.contactName" clearable></el-input>
            </div>
            <div>
              <i style="color: red">* </i>
              <span>联系电话：</span>
              <el-input type="number" placeholder="请输入联系电话" v-model="paramsObj.phone" clearable> </el-input>
            </div>
            <div>
              <i style="color: red">* </i>
              <span>经度：</span>
              <el-input type="number" placeholder="请输入经度" v-model="paramsObj.longitude" clearable></el-input>
            </div>
            <div>
              <i style="color: red">* </i>
              <span>纬度：</span>
              <el-input type="number" placeholder="请输入纬度" v-model="paramsObj.latitude" clearable></el-input>
            </div>
            <div v-if="isUseFlag">
              <i style="color: red">* </i>
              <span>状态：</span>
              <el-select v-model="paramsObj.useFlag" clearable>
                <el-option :value="0" label="启用"></el-option>
                <el-option :value="1" label="禁用"></el-option>
              </el-select>
            </div>
          </div>
          <div class="newDialog" v-if="isLook">
            <div>
              <span>机构名称：</span>
              <el-input v-model="lgxqyName" disabled clearable></el-input>
            </div>
            <div>
              <span>AppId：</span>
              <el-input v-model="lAppid" disabled clearable></el-input>
            </div>
            <div>
              <span>数据接入密钥：</span>
              <el-input style="vertical-align: middle" v-model="lsjmy" disabled clearable></el-input>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer" v-if="!isLook">
          <el-button @click="cancles">取 消</el-button>
          <el-button type="primary" @click="confirmClk">确 定</el-button>
        </span>
        <span slot="footer" class="dialog-footer" v-if="isLook">
          <el-button @click="dialogVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
import { organizationQueryWithPage, organizationSave, organizationUpdate, organizationDelete } from '@/util/http'
import inputList from '@/components/inputList/inputList.vue'
import ToTop from '@/components/toTop/toTop.vue'
export default {
  components: { inputList, ToTop },
  data() {
    return {
      currpage: 1,
      pagesize: 10,
      totals: 0,
      tableData: [], //数据
      cgxqy: '', //区域名称id
      cjgmc: '', //查询机构名称
      cTelephoneNum: '', //查询联系电话
      cTeleName: '', //查询联系人姓名
      cSuperiorArr: '', //区域名称数组
      lgxqyName: '', //查看机构名称
      lAppid: '', //查看AppId
      lsjmy: '', //查看数据接入密钥
      isgxqy: true, //新增编辑区域下拉框显示影藏
      user: '',
      paramsObj: {}, //新增编辑对象
      dialogVisible: false, //模态框显影
      dialogTitle: '', //模态框标题
      isUseFlag: false, //状态显影
      dialogType: '', //新增编辑标记
      dialogRow: {}, //当前点击的一条数据
      isLook: false, //查看数据授权码
    }
  },
  methods: {
    // 拿值区域和机构
    getData(msg) {
      this.cgxqy = msg[0]
      this.cjgmc = msg[1]
    },
    // 分页
    handleSizeChange(val) {
      this.pagesize = val
      this.searchs(this.currpage, val)
    },
    handleCurrentChange(val) {
      this.currpage = val
      this.searchs(val, this.pagesize)
    },
    // 查询
    async searchs(currpage, pagesize) {
      const loading = this.$loading({
        lock: true,
        text: '拼命加载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
      })
      this.currpage = currpage
      let params = {
        pageNo: currpage,
        pageSize: pagesize,
        id: this.cjgmc,
        phone: this.cTelephoneNum,
        contactName: this.cTeleName,
        areaId: this.cgxqy,
      }
      await organizationQueryWithPage(params)
        .then((data) => {
          this.tableData = data.result.records
          this.totals = data.result.total
        })
        .catch(() => {})
      loading.close()
    },
    // 重置
    reserts() {
      this.currpage = 1
      this.pagesize = 10
      this.$bus.$emit('clickResert', 1)
      this.cgxqy = this.user.areaId
      this.cjgmc = this.user.organizationId
      this.cTelephoneNum = ''
      this.cTeleName = ''
      this.searchs(1, 10)
    },
    // 取消
    cancles() {
      this.dialogVisible = false
      this.paramsObj = {}
    },
    // 展开模态框
    openDialog(flag, row) {
      this.dialogType = flag
      if (flag == 0) {
        if (this.user.areaId != '') {
          this.paramsObj.areaId = this.user.areaId
        }
        this.dialogTitle = '新增机构'
        this.isUseFlag = false
        this.paramsObj.useFlag = 0
        this.isLook = false
      } else if (flag == 1) {
        this.paramsObj = Object.assign({}, row)
        this.dialogRow = row
        this.dialogTitle = '编辑机构'
        this.isUseFlag = true
        this.isLook = false
      } else if (flag == 2) {
        this.isLook = true
        this.dialogTitle = '查看数据接入授权码'
        this.lgxqyName = row.name
        this.lAppid = row.id
        this.lsjmy = row.appkey
      }
      this.dialogVisible = true
    },
    // 确认
    confirmClk() {
      if (!this.paramsObj.areaId) {
        this.$message.error('区域名称不能为空')
        return
      }
      if (!this.paramsObj.name) {
        this.$message.error('机构名称不能为空')
        return
      }
      if (!this.paramsObj.address) {
        this.$message.error('地址不能为空')
        return
      }
      if (!this.paramsObj.contactName) {
        this.$message.error('联系人不能为空')
        return
      }
      if (!this.paramsObj.phone || String(this.paramsObj.phone.length) != 11) {
        this.$message.error('联系电话不能为空或长度不为11位')
        return
      }
      if (!this.paramsObj.longitude) {
        this.$message.error('经度不能为空')
        return
      }
      if (!this.paramsObj.latitude) {
        this.$message.error('纬度不能为空')
        return
      }
      if (String(this.paramsObj.useFlag) == '' && this.dialogType == 1) {
        this.$message.error('状态不能为空')
        return
      }
      this.paramsObj = qs.stringify(this.paramsObj)
      if (this.dialogType == 0) {
        organizationSave(this.paramsObj).then((data) => {
          if (data) this.returnState(data)
        })
      } else if (this.dialogType == 1) {
        organizationUpdate(this.paramsObj).then((data) => {
          if (data) this.returnState(data)
        })
      }
    },
    // 删除
    deletes(id) {
      this.$confirm('是否删除该条数据?', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          organizationDelete(qs.stringify({ id })).then((data) => {
            if (data) {
              let deleteAfterPage = Math.ceil((this.totals - 1) / this.pagesize)
              let currentPage = this.currpage > deleteAfterPage ? deleteAfterPage : this.currpage
              this.currpage = currentPage < 1 ? 1 : currentPage
              this.returnState(data)
            }
          })
        })
        .catch(() => {})
    },
    // 返回状态
    async returnState(data) {
      await this.$message.success(data.message)
      this.cancles()
      this.searchs(this.currpage, this.pagesize)
    },
  },
  mounted() {
    this.user = this.$store.state.user
    this.cgxqy = this.user.areaId
    this.cjgmc = this.user.organizationId
    if (!this.cgxqy) {
      this.isgxqy = true
    } else {
      this.isgxqy = false
    }
    this.cSuperiorArr = JSON.parse(sessionStorage.getItem('gxqylist')) //区域名称数组
    this.searchs(1, 10)
  },
}
</script>
<style src="../../public/css/search.less" lang="less" scoped></style>
<style lang="less" scoped>
.cont {
  width: 100%;
}
</style>
